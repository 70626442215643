import CustomerService from "../../api/services/customer";

const initialState = {
  isLoading: false,
  plans: [],
};

export default {
  namespaced: true,

  state: initialState,

  mutations: {
    setPlans(state, plans) {
      state.plans = plans;
    },

    setIsLoading(state, val) {
      state.isLoading = val;
    },

    resetState(state) {
      state.isLoading = initialState.isLoading;
      state.plans = initialState.plans;
    },
  },

  actions: {
    reinitializeCustomerState({ commit }) {
      commit("resetState");
    },

    withLoader({ commit }, action) {
      commit("setIsLoading", true);
      return action().finally(() => {
        commit("setIsLoading", false);
      });
    },

    async getPlans({ commit, dispatch }) {
      dispatch("withLoader", async () => {
        const response = await CustomerService.getPlans();
        if (response?.success) {
          const { plans } = { ...response.data };
          commit("setPlans", plans);
        }
        return response;
      });
    },
  },
};
