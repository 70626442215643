<template>
  <div>
    <h1 class="text-4xl font-heading-1 pb-4 pl-2 font-extrabold tracking-tight text-gray-900">
      {{ $t("Payment methods") }}
    </h1>
    <Table
      :columns="columns"
      :data="formattedPaymentMethods"
      :is-updatable="true"
      @update="redirectUpdatePaymentMethodLink"
    >
      <template #paymentMethod="{ row, column }">
        <span class="flex items-center space-x-2">
          <component
            v-if="row[column.field].icon"
            :is="iconComponents[row[column.field].icon]"
            class="h-5 w-5 text-primary-dark"
          />
          <span v-html="row[column.field].label"></span>
        </span>
      </template>
    </Table>
  </div>
</template>

<script>
import PlanService from "@/api/services/plan";
import { Table } from "@estaly/ui";
import { mapActions } from "vuex";
import { CreditCardIcon, HomeIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Table,
  },
  data: () => {
    return {
      paymentMethods: [],
      columns: [
        { label: "Numéro de contrat", field: "custom_id", bold: true },
        { label: "Moyen de paiement", field: "paymentMethod", slot: "paymentMethod" },
      ],
      iconComponents: {
        CreditCardIcon,
        HomeIcon,
      },
    };
  },
  computed: {
    formattedPaymentMethods() {
      return this.paymentMethods.map((method) => ({
        ...method,
        paymentMethod: {
          icon: method.type === "card" ? "CreditCardIcon" : "HomeIcon",
          label: `${method.brand} **** ${method.digits}`,
        },
      }));
    },
  },
  created() {
    this.retrievePaymentMethods();
  },
  methods: {
    ...mapActions("notifications", ["notify"]),

    async retrievePaymentMethods() {
      const response = await PlanService.getPaymentMethods();
      if (!response?.success) return;

      this.paymentMethods = response.data.payment_methods;
    },
    async redirectUpdatePaymentMethodLink(plan) {
      const response = await PlanService.getUpdatePaymentMethodLink(plan.id);
      if (!response?.success) return;

      window.open(response.data.update_link, "_blank");
    },
  },
};
</script>
