<template>
  <CancellationFirstStep
    v-if="this.step == 0"
    @next="next"
    @reason="selectedReason"
    @cancellationDate="getCancellationDate"
  />
  <CancellationSecondStep
    v-if="this.step == 1"
    @feedbackExperience="getFeedbackExperience"
    @feedbackMessage="getFeedbackMessage"
    @cancelPlan="cancelPlan"
    :reason="this.reason"
  />
</template>

<script>
import { mapActions } from "vuex";
import CancellationFirstStep from "../../components/cancellation/CancellationFirstStep.vue";
import CancellationSecondStep from "../../components/cancellation/CancellationSecondStep.vue";
import PlanService from "../../api/services/plan.js";

export default {
  components: {
    CancellationFirstStep,
    CancellationSecondStep,
  },

  data: () => {
    return {
      step: 0,
      cancellationDate: "",
      reason: "",
      feedbackExperience: "",
      feedbackMessage: "",
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    next() {
      this.step = this.step + 1;
    },

    selectedReason(value) {
      this.reason = value;
    },

    getCancellationDate(value) {
      this.cancellationDate = value;
    },

    getFeedbackExperience(value) {
      this.feedbackExperience = value;
    },

    getFeedbackMessage(value) {
      this.feedbackMessage = value;
    },

    async cancelPlan() {
      const params = {
        feedback_experience: this.feedbackExperience,
        feedback_message: this.feedbackMessage,
        cancellation_reason: this.reason,
        cancellation_date: this.cancellationDate,
      };
      const { success } = await PlanService.cancelPlan(this.$route.params.id, params);
      if (success) this.$router.push("/plans");
    },

    closeModal() {
      this.$router.push("/plans");
    },
  },
};
</script>
