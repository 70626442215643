import AuthService from "../../api/services/auth";
import UserService from "../../api/services/user";
import { isPresent } from "@/utils/validation";

export default {
  namespaced: true,
  state: {
    currentUser: {},
    signedIn: false,
    csrf: null,
  },

  getters: {
    isStore: (state) => state.currentUser.role === "organization",
    isCustomer: (state) => state.currentUser.role === "customer",

    currentUserHasPermission: (state) => (permission) => {
      if (!permission) {
        return true;
      }

      return isPresent(state.currentUser?.permissions) ? state.currentUser.permissions.includes(permission) : false;
    },
  },

  mutations: {
    setCurrentUser(state, { currentUser, csrf }) {
      state.currentUser = currentUser;
      state.signedIn = true;
      state.csrf = csrf;
    },

    unsetCurrentUser(state) {
      state.currentUser = {};
      state.signedIn = false;
      state.csrf = null;
    },

    refresh(state, csrf) {
      state.csrf = csrf;
      state.signedIn = true;
    },
  },

  actions: {
    async signin({ commit }, user) {
      const response = await AuthService.signin(user);
      if (!response?.success) return response;

      const csrf = response.data.csrf;
      if (csrf) {
        const userResponse = await UserService.getUser();
        const currentUser = userResponse.data;
        commit("setCurrentUser", { currentUser, csrf });
      } else {
        commit("unsetCurrentUser");
      }
      return response;
    },

    async signup({ commit }, params) {
      const response = await AuthService.signup(params);
      if (!response?.success) return response;

      const csrf = response.data.csrf;
      if (csrf) {
        const userResponse = await UserService.getUser();
        const currentUser = userResponse.data;
        commit("setCurrentUser", { currentUser, csrf });
      } else {
        commit("unsetCurrentUser");
      }
      return response;
    },

    async resetPassword({ commit }, { resetPasswordToken, payload }) {
      const response = await AuthService.resetPassword(resetPasswordToken, payload);
      if (!response?.success) return response;

      const csrf = response.data.csrf;
      if (csrf) {
        const userResponse = await UserService.getUser();
        const currentUser = userResponse.data;
        commit("setCurrentUser", { currentUser, csrf });
      } else {
        commit("unsetCurrentUser");
      }
      return response;
    },

    async signout({ commit }) {
      const response = await AuthService.signout();
      if (!response?.success) return response;

      commit("unsetCurrentUser");
      localStorage.clear();
      return response;
    },
  },
};
