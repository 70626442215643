<template>
  <div>
    <transition
      enter-active-class="transition ease-out duration-1000"
      enter-from-class="transform opacity-0 scale-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-700"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-100"
    >
      <div class="h-24 w-full bg-primary-light">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full">
          <div class="flex justify-between items-center h-full">
            <div class="flex-shrink-0 flex items-center px-4">
              <a href="https://www.estaly.co/">
                <img class="h-12 w-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly" />
              </a>
            </div>
            <div class="flex justify-end">
              <router-link to="/signin">
                <button
                  class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow disabled:cursor-wait disabled:opacity-75 text-button-font-primary bg-primary uppercase"
                >
                  {{ $t("Log In") }}
                </button>
              </router-link>
            </div>
            <transition
              enter-active-class="transition-opacity duration-1000"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity duration-700"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            ></transition>
          </div>
        </div>
      </div>
    </transition>
    <div class="py-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div>
          <div class="pb-4 pl-2 pt-10 text-center">
            <h1 class="text-3xl font-extrabold tracking-tight text-gray-90">
              {{ $t("Start a new claim") }}
            </h1>
            <p class="mt-2 text-sm text-gray-500">
              {{ $t("To retrieve your contract, we need your plan ID and your email address.") }}
            </p>
            <p class="mt-2 text-sm text-gray-500">
              {{
                $t(
                  "You can find your Plan ID (ex : CO12BI250NTA-SHOP-1) in your confirmation email or in your subscription form.",
                )
              }}
            </p>
          </div>
          <VeeForm v-slot="{ handleSubmit }" v-bind:validation-schema="schema" as="div" class="w-full" ref="form">
            <form v-on:submit.prevent="handleSubmit($event, handleStartAClaim)">
              <div class="pt-10 flex flex-col items-center">
                <div class="w-4/5 sm:w-1/3">
                  <label for="plan-id" class="block text-sm font-medium text-gray-700">{{ $t("Plan ID") }}</label>
                  <Field
                    type="text"
                    name="planId"
                    id="plan-id"
                    class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    v-model="planInfo.planId"
                  />
                  <ErrorMessage name="planId" class="mt-2 text-xs text-red-600" />
                </div>

                <div class="pt-6 w-4/5 sm:w-1/3">
                  <label for="email" class="block text-sm font-medium text-gray-700">{{ $t("Email address") }}</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    autocomplete="email"
                    class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    v-model="planInfo.email"
                  />
                  <ErrorMessage name="email" class="mt-2 text-xs text-red-600" />
                </div>
              </div>

              <div class="flex justify-end mt-12">
                <button
                  type="submit"
                  class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow disabled:cursor-wait disabled:opacity-75 text-button-font-primary bg-primary uppercase"
                >
                  {{ $t("Start") }}
                </button>
              </div>
            </form>
          </VeeForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import ClaimService from "../api/services/claim";
import { mapActions } from "vuex";

export default {
  components: { Field, VeeForm, ErrorMessage },

  data: () => {
    const schema = yup.object({
      planId: yup.string().required("Please enter your Plan ID"),
      email: yup.string().required("Email is required").email("Email is wrong"),
    });
    return {
      planInfo: {},
      schema,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    startAClaim: async function () {
      const planParams = {
        planId: this.planInfo.planId,
        email: this.planInfo.email,
      };
      const response = await ClaimService.retrieve({ planParams });
      if (!response?.success) return response;

      this.notify({
        category: "simple",
        type: "success",
        title: "Your contract has been found!",
        text: "Please fill the form.",
      });
      this.$router.push(`/file-a-claim/${response.data.planId}/${response.data.startDate}`);
    },

    handleStartAClaim: async function () {
      await this.startAClaim();
    },
  },
};
</script>
