import { securedAxiosInstance } from "../axios";

class CustomerService {
  getPlans() {
    return securedAxiosInstance.get("/customer/plan_sales");
  }

  getClaimsByPlanSaleId(planSaleId) {
    return securedAxiosInstance.get(`/customer/plan_sales/${planSaleId}/claims`);
  }

  getPlanMembershipAgreement(planSaleId) {
    return securedAxiosInstance.get(`/customer/plan_sales/${planSaleId}/membership_agreement`);
  }

  getClaims() {
    return securedAxiosInstance.get("/customer/claims");
  }

  getClaimByFriendlyId(claimId) {
    return securedAxiosInstance.get(`/claims/${claimId}`);
  }

  getClaimDeductiblePaymentStripeUrl(encryptedId) {
    return securedAxiosInstance.get(`/claims/deductible_payment`, {
      params: {
        encrypted_id: encryptedId,
      },
    });
  }

  sendComplaint(message) {
    const data = {
      complaint: message,
    };
    return securedAxiosInstance.post(`/customer/complaints`, data);
  }

  getPlanSaleBillings(page) {
    return securedAxiosInstance.get(`/customer/plan_sale_billings?page=${page}`);
  }

  getPlanSaleBillingInvoice(planSaleBillingId) {
    return securedAxiosInstance.get(`/customer/plan_sale_billings/${planSaleBillingId}`);
  }
}

export default new CustomerService();
