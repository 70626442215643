import { Product, filterProducts } from "../utils";

const initialState = {
  isLoading: false,
  allProducts: [],
  filteredProducts: [],
  availableFilters: {
    productTypes: [],
    vendors: [],
    tags: [],
    offerStatuses: [],
  },
  selectedFilters: {
    productTypes: [],
    vendors: [],
    tags: [],
    offerStatuses: [],
  },
  search: "",
  productsToUpdate: [],
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    hasProductsToUpdate: (state) => {
      return state.productsToUpdate.length > 0;
    },

    hasFilterSelected: (state) => {
      return Object.values(state.selectedFilters).some((options) => options.length > 0);
    },
  },

  mutations: {
    resetState(state) {
      state.isLoading = initialState.isLoading;
      state.allProducts = initialState.allProducts;
      state.filteredProducts = initialState.filteredProducts;
      state.availableFilters = initialState.availableFilters;
      state.selectedFilters = initialState.selectedFilters;
      state.search = initialState.search;
      state.productsToUpdate = initialState.productsToUpdate;
    },

    setProducts(state, products) {
      let parents = [...products.filter((p) => p.parentId === undefined)];
      let variants = [...products.filter((p) => p.parentId !== undefined)];
      parents.forEach((p) => (p.variants = variants.filter((v) => v.parentId === p.id)));
      state.allProducts = parents;
    },

    setAvailableFilters(state, { productTypes, vendors, tags, offerStatuses }) {
      state.availableFilters.productTypes = productTypes;
      state.availableFilters.vendors = vendors;
      state.availableFilters.tags = tags;
      state.availableFilters.offerStatuses = offerStatuses;
    },

    productToUpdateAdded(state, product) {
      state.productsToUpdate.push(product);
    },

    productToUpdateRemoved(state, id) {
      state.productsToUpdate = state.productsToUpdate.filter((product) => product.id !== id);
    },

    productsToUpdateCleared(state) {
      state.productsToUpdate = [];
    },

    setIsLoading(state, val) {
      state.isLoading = val;
    },

    setFilteredProducts(state) {
      const products = [...state.allProducts];
      state.filteredProducts = products;
      state.filteredProducts = filterProducts(state.selectedFilters, state.search, products);
    },

    setFilter(state, { filterName, filterOptions }) {
      state.selectedFilters[filterName] = filterOptions;
    },

    setSearch(state, searchString) {
      state.search = searchString;
    },

    filtersReseted(state) {
      for (const filter of Object.keys(state.selectedFilters)) {
        state.selectedFilters[filter] = [];
      }
    },
  },

  actions: {
    filterProducts({ commit }) {
      commit("setFilteredProducts");
    },

    updateFilter({ commit, dispatch }, { filterName, filterOptions }) {
      commit("setFilter", { filterName, filterOptions });
      dispatch("filterProducts");
    },

    reinitializeStoreState({ commit }) {
      commit("resetState");
    },

    updateSearch({ commit, dispatch }, searchString) {
      commit("setSearch", searchString);
      dispatch("filterProducts");
    },

    addProductToUpdate({ commit }, { id, enabled }) {
      const product = new Product(id, enabled);
      commit("productToUpdateAdded", product);
    },

    removeProductToUpdate({ commit }, id) {
      commit("productToUpdateRemoved", id);
    },

    clearProductsToUpdate({ commit }) {
      commit("productsToUpdateCleared");
    },

    resetFilters({ commit }) {
      commit("filtersReseted");
    },

    withLoader({ commit }, action) {
      commit("setIsLoading", true);
      return action().finally(() => {
        commit("setIsLoading", false);
      });
    },
  },
};
