<template>
  <CustomerClaimList v-bind:claims="this.claims" />
</template>
<script>
import CustomerService from "@/api/services/customer";
import CustomerClaimList from "@/views/customer/claim/list/CustomerClaimList.vue";

export default {
  components: {
    CustomerClaimList,
  },

  data() {
    return {
      claims: [],
    };
  },

  created() {
    this.getClaims();
  },

  methods: {
    async getClaims() {
      const response = await CustomerService.getClaims();
      if (response?.success) this.claims = response.data.claims;
    },
  },
};
</script>
